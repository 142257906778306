define("projektitekt/components/loading-spinner.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "sk-cube-grid": "_sk-cube-grid_5dbwjb",
    "sk-cube": "_sk-cube_5dbwjb",
    "sk-cubeGridScaleDelay": "_sk-cubeGridScaleDelay_5dbwjb",
    "sk-cube1": "_sk-cube1_5dbwjb",
    "sk-cube2": "_sk-cube2_5dbwjb",
    "sk-cube3": "_sk-cube3_5dbwjb",
    "sk-cube4": "_sk-cube4_5dbwjb",
    "sk-cube5": "_sk-cube5_5dbwjb",
    "sk-cube6": "_sk-cube6_5dbwjb",
    "sk-cube7": "_sk-cube7_5dbwjb",
    "sk-cube8": "_sk-cube8_5dbwjb",
    "sk-cube9": "_sk-cube9_5dbwjb"
  };
  _exports.default = _default;
});