define("projektitekt/components/remember-document-scroll", ["exports", "memory-scroll/components/remember-document-scroll"], function (_exports, _rememberDocumentScroll) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _rememberDocumentScroll.default;
    }
  });
});