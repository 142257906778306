define("projektitekt/components/page-progress", ["exports", "projektitekt/templates/components/page-progress"], function (_exports, _pageProgress) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _pageProgress.default,
    tagName: '',
    pageProgress: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      var configs = Ember.getProperties(this, 'speed', 'minimum', 'background');
      Ember.get(this, 'pageProgress').setConfig(configs);
    },
    barStyle: Ember.computed('pageProgress.barStyle', {
      get: function get() {
        return Ember.String.htmlSafe(Ember.get(this, 'pageProgress.barStyle'));
      }
    }).readOnly()
  });

  _exports.default = _default;
});