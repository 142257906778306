define("projektitekt/components/footer.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "footer": "_footer_8ix6as",
    "top": "_top_8ix6as",
    "bottom-content": "_bottom-content_8ix6as",
    "name-box": "_name-box_8ix6as",
    "logo": "_logo_8ix6as",
    "name": "_name_8ix6as",
    "title": "_title_8ix6as",
    "box": "_box_8ix6as",
    "box-logo": "_box-logo_8ix6as",
    "box-text": "_box-text_8ix6as",
    "bottom": "_bottom_8ix6as",
    "copyright": "_copyright_8ix6as",
    "links": "_links_8ix6as",
    "address-box": "_address-box_8ix6as",
    "mail-box": "_mail-box_8ix6as"
  };
  _exports.default = _default;
});