define("projektitekt/components/header.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "header": "_header_14ak88",
    "spacer": "_spacer_14ak88",
    "content": "_content_14ak88",
    "logo-link": "_logo-link_14ak88",
    "logo": "_logo_14ak88",
    "links": "_links_14ak88"
  };
  _exports.default = _default;
});