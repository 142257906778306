define("projektitekt/styles/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "photo-wrapper": "_photo-wrapper_g0l5af",
    "photo": "_photo_g0l5af",
    "social-media-button": "_social-media-button_g0l5af"
  };
  _exports.default = _default;
});