define("projektitekt/templates/components/page-progress", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tI50y8uI",
    "block": "{\"symbols\":[],\"statements\":[[9,\"div\",true],[12,\"class\",\"page-progress\",null],[10],[1,1,0,0,\"\\n  \"],[9,\"div\",true],[12,\"class\",\"bar\",null],[12,\"data-test-id\",\"data-test-page-progress-bar\",null],[13,\"style\",[27,[26,0,\"AppendSingleId\"],[]],null],[10],[1,1,0,0,\"\\n  \"],[11],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"barStyle\"]}",
    "meta": {
      "moduleName": "projektitekt/templates/components/page-progress.hbs"
    }
  });

  _exports.default = _default;
});