define("projektitekt/components/bingo-grid.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "grid": "_grid_tsj1cp",
    "item": "_item_tsj1cp",
    "text": "_text_tsj1cp",
    "hidden": "_hidden_tsj1cp",
    "speaker": "_speaker_tsj1cp",
    "cat": "_cat_tsj1cp"
  };
  _exports.default = _default;
});