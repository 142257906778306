define("projektitekt/templates/head", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LWf/eDms",
    "block": "{\"symbols\":[],\"statements\":[[9,\"title\",true],[10],[1,0,0,0,[27,[24,0],[\"model\",\"title\"]]],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "projektitekt/templates/head.hbs"
    }
  });

  _exports.default = _default;
});