define("projektitekt/styles/news", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "subtitle": "_subtitle_1380gq",
    "centered": "_centered_1380gq",
    "loading": "_loading_1380gq",
    "error": "_error_1380gq",
    "list": "_list_1380gq",
    "caption-column": "_caption-column_1380gq",
    "caption": "_caption_1380gq",
    "date": "_date_1380gq"
  };
  _exports.default = _default;
});